import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";

Vue.config.productionTip = false;

// 引入mock
// import "./mock/index";

// 引入api
import API from "./api";

Vue.prototype.$API = API;
import axios from "axios";
Vue.prototype.$axios = axios;

// 处理页面操作，全局混入
import "@/utils/mixin.js";

// 引入本地存储
import { localSetItem, localGetItem, localRemoveItem } from "@/config/local";
Vue.prototype.$localSetItem = localSetItem;
Vue.prototype.$localGetItem = localGetItem;
Vue.prototype.$localRemoveItem = localRemoveItem;

// 引入不知道干啥用的
import { pageScrollTop, BASE_URL, OSS_IMG_BASE_URL } from "./config/index";
Vue.prototype.$pageScrollTop = pageScrollTop;
import { Message } from "element-ui";

// 如果有token
let token = localStorage.getItem("user-token");
if (token) {
  store.commit("setToken", token);
}
// 路由权限管理;
router.beforeEach(async (to, from, next) => {
  console.log("路由===", to, from);
  if (store.state.token) {
    // 有token，去登录的，跳转去首页
    if (to.path === "/login") {
      next("/");
    } else {
      // 如果vuex中存在用户信息，就放行
      if (Object.keys(store.state.userInfo).length) {
        next();
      } else {
        // 没有用户信息  获取用户信息  获取不到说明token失效或者其它问题，都跳去登录
        // 最好是把根据token获取用户信息的操作，写到vuex的action中，因为用户信息和token都保存在vuex中，便于操作
        try {
          await store.dispatch("getUserInfo");
          next({ ...to, replace: true });
        } catch (err) {
          console.log(err);
          // 获取不到说明token失效或者其它问题，都跳去登录，
          // 并且需要本地存储的token，vuex中的token，都清理掉，就像新用户访问网站一样
          store.commit("clearInfo");
          next("/login");
        }

        // try {
        // 这是个promise, 得捕获它的错误，目的是把错误抛出去

        // API.getInfo().then(res => {
        //   if (res.code == 1) {
        //     store.commit("setInfo", res.data);
        //     // console.log(store)
        //     // console.log('mainres', res.data)
        //     next({ ...to, replace: true })  // 这样写是为了让路由自行回调，确保上边的用户信息得到
        //   } else {
        //     throw Error(res.msg)
        //   }
        // }).catch(err => {
        //   // 这里是根据token获取不到用户信息别动了
        //   store.commit('clearInfo', {});
        //   next("/login")
        // })

        // } catch (error) {
        //   store.commit('clearInfo');
        //   next("/login")
        // }
      }
    }
  } else {
    // 没有token
    if (to.meta) {
      // 不需要鉴权
      if (to.meta.noNeedLogin) {
        next();
        // console.log("不需要鉴权")
      } else {
        // 需要鉴权
        // console.log("需要鉴权")
        next("/login");
      }
    }
  }
});
// 折叠展开所需要的
let timer = null;
window.onresize = function () {
  if (timer) {
    clearTimeout(timer);
    timer = setTimeout(() => {
      busEvent.$emit("resize");
    }, 500);
  }
};

/**
 * ! 是否可以跳转详情
 * @param {Object} params 请求参数
 * @param {Boolean} sign 如果有值的话 是否需要返回 默认 false
 * @returns  BOOLEAN OBJECT
 */
Vue.prototype.$isEnterDetailFun = async function (params, sign) {
  sign = sign || false;
  try {
    let res = await API.isEnterDetail(params);
    // ├─ is_pay	Integer[整数]	是否支付 0 否 1是   (1 不能; 0 能;)
    // ├─ score	Integer[整数]	积分
    // ├─ user_money	Float[浮点数]	余额
    // ├─ score_pay	Integer[整数]	积分支付  (需要支付的积分)
    // ├─ user_money_pay	Float[浮点数]	余额支付金额  (需要支付的金额)
    if (res.code == 1) {
      if (sign) {
        return res.data;
      }

      return res.data.is_pay;
    }
  } catch (error) {
    return "catch";
  }
};

Vue.prototype.$config = { BASE_URL, OSS_IMG_BASE_URL };

/**
 * ! 替换  最后一级为空的时候 cascader 展示暂无数据函数
 * @param {Array,Object} gData 源对象
 * @param {Object} gOpt 配置项
 * 			{
 * 				d: 'children', 需要替换的子集 key
 * 			}
 */
Vue.prototype.$replaceChildrenEmpty = function (gData, gOpt) {
  function fun(data, opt) {
    let { d = "children" } = opt;
    if (!(data instanceof Object) && !(data instanceof Array)) {
      console.log("需要处理的数据 不是对象也不是数组");
      return data;
    }

    if (!(data instanceof Array) && data instanceof Object) {
      data = data[d];
    }

    data.forEach((item) => {
      if (!item[d] || item[d].length < 1) {
        item[d] = null;
      } else {
        fun(item[d], opt);
      }
    });

    return data;
  }

  return fun(gData, gOpt);
};

// 引入element-ui
import ElementUI from "element-ui";
Vue.use(ElementUI);
import "element-ui/lib/theme-chalk/index.css";
import busEvent from "./busEvent";

new Vue({
  router,
  store,
  render: function (h) {
    return h(App);
  },
}).$mount("#app");
