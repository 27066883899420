<template>
  <div class="nav-contaner">
    <el-row class="contaner">
      <el-row class="nav" type="flex" align="middle" justify="space-between">
        <!-- 左侧部分 -->
        <el-row class="left">
          <router-link :to="{ name: 'home' }">
            <img src="@/assets/images/home/header.png" alt="" />
          </router-link>
        </el-row>

        <!-- 右侧用户头像和昵称 -->
        <el-row class="right">
          <!-- 已经登录过 -->
          <el-row
            class="user"
            type="flex"
            align="middle"
            @click="toPersonal"
            v-if="$store.state.userInfo.nickName"
          >
            <img
              v-if="$store.state.userInfo.avatar"
              style="border-radius: 50%"
              @click="toPersonal"
              :src="$store.state.userInfo.avatar"
              alt=""
            />
            <img v-else style="border-radius: 50%" @click="toPersonal" src="https://cloud.tiantianhuoke.com/static/admin/images/user_none.png" alt="">
            <span @click="toPersonal">{{
              $store.state.userInfo.nickName
            }}</span>
          </el-row>
          <!-- 未登录 -->
          <el-row class="user" type="flex" align="middle" v-else>
            <router-link :to="{ name: 'login' }">
              <img src="@/assets/images/home/no-load.png" alt="" />
              <span class="no-load">未登录</span>
            </router-link>
          </el-row>
        </el-row>
      </el-row>
    </el-row>
  </div>
</template>

<script>
export default {
  name: "YoufuJichuangNav",

  data() {
    return {
      isLogin: false, //是否登录
    };
  },

  mounted() {},

  methods: {
    // 去个人中心部分
    toPersonal() {
      console.log("0000");
      this.$router.push("/contact/personal");
    },
  },
};
</script>

<style lang="scss" scoped>
.contaner {
  background-color: #fff;
}
.nav {
  width: 100%;
  height: 90px;
  background-color: #fff;
  position: relative;
  .left {
    img {
      width: 140px;
      height: 31.73px;
    }
  }
  .right {
    .user {
      height: 40px;
      // line-height: 40px;
      cursor: pointer;
      .no-load {
        color: #757575;
      }
    }
    img {
      width: 40px;
      height: 40px;
      margin-right: 10px;
      vertical-align: middle;
    }
    span {
      font-size: 16px;
      color: #333;
    }
  }
}
</style>